@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700&display=swap');

.logo-text{
  font-family: 'Lobster', cursive;
  color: #AAA28C ;
}




body{
  background: #25282d;
  color: white;
  font-family: 'Nunito', sans-serif;
}

header{
  background: #181C22;
}


.tagline{
  color:#D64AB7;
}

.category-description{
  color:#B0BCC8;
}


.tag-free-shipping{
background:#C06ECD;
}

.tag-one-left{
  background:#D9578D;
}

.item{
  background: #2a2f33;
}


.add-to-cart-button{
  width: 100%;
background: linear-gradient(90deg, #E69B57 0%, #E65757 100%);
border-radius: 99px;

}

.description{
  color:#BCB1B1;
}

